const getSlugFromParams = (slug: string | string[] | undefined | null) => (Array.isArray(slug)
  ? slug.join('/')
  : slug);

const removeLocaleCodeFromUrl = (url: string, locale: string): string => {
  // Define the locale patterns
  const localePatternInPath = new RegExp(`/${locale}/`, 'g');
  const localePatternAtEnd = new RegExp(`/${locale}$`);

  // Replace `/${locale}/` in the path
  let updatedUrl = url.replace(localePatternInPath, '/');

  // Replace `/${locale}` at the end
  updatedUrl = updatedUrl.replace(localePatternAtEnd, '/');

  return updatedUrl;
};

const addQueryParamsToPath = (path: string, queryParams: Record<string, string>): string => {
  const searchParams = new URLSearchParams(path.split('?')[1] ?? '');

  Object.entries(queryParams).forEach(([
    key,
    value,
  ]) => {
    searchParams.set(key, value);
  });

  return `${path.split('?')[0]}?${searchParams.toString()}`;
};

const isValidUrl = (url: string): boolean => !!url.match(/^(?:(?:https?):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i);

export {
  addQueryParamsToPath,
  getSlugFromParams,
  isValidUrl,
  removeLocaleCodeFromUrl,
};
